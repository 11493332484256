import React from 'react';

import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Header from '../components/Header';
import SideFooter from '../components/SideFooter';

import heroImage from '../assets/images/pic01.jpg';
import Gallery from '../components/Gallery';
import ContactForm from '../components/ContactForm';
import { Link } from 'gatsby';

function createImageSet(baseName, numberOfImages) {
  const imageSet = [];
  for (var i = 1; i <= numberOfImages; i++) {
    imageSet.push({
      src: require('../assets/images/gallery/fulls/' + baseName +  '-' + i + '.jpeg'),
      thumbnail: require('../assets/images/gallery/fulls/' + baseName +  '-' + i + '.jpeg'),
      title: '',
      desc: '',
      full: false,
    });
  }
  return imageSet
}

const lawn_images_set = createImageSet("lawn", 10);
const construction_images_set = createImageSet("construction", 10);
const fence_images_set = createImageSet("fence", 2);
const maintenance_images_set = createImageSet("maintenance", 4);

const IndexPage = () => (
  <Layout>
    <div id="wrapper">
      <section className="intro">
        <Header />
        <div className="content">
          <span className="image fill" data-position="center">
            <img src={heroImage} alt="" />
          </span>
        </div>
      </section>

      <section id="first">
        <header>
          <h2>
            Gazon
          </h2>
        </header>
        <div className="content">
          <div className="content">
            <Gallery images={lawn_images_set} />
          </div>
        </div>
      </section>

      <section>
      <header>
        <h2>
          Grond - en klinkerwerken
        </h2>
      </header>
      <div className="content">
        <div className="content">
          <Gallery images={construction_images_set} />
        </div>
      </div>
      </section>

      <section>
      <header>
        <h2>
          Omheiningen
        </h2>
      </header>
      <div className="content">
        <div className="content">
          <Gallery images={fence_images_set} />
        </div>
      </div>
      </section>

      <section>
      <header>
        <h2>
          Onderhoud
        </h2>
      </header>
      <div className="content">
        <div className="content">
          <Gallery images={maintenance_images_set} />
        </div>
      </div>
      </section>
      <section id="contact">
        <ContactForm />
        <SideFooter />
      </section>

      <Footer />
    </div>
  </Layout>
);

export default IndexPage;
