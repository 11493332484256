module.exports = {
  siteTitle: 'Tuinen De Jean', // <title>
  manifestName: 'paradigmshift',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: ``, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Tuinen De Jean',
  subHeading: 'Tuinen de Jean is een bedrijf gevestigd in Nijlen. We spitsen ons vooral toe op het aanleggen van gazons, zowel in zaaien als plaatsen van grasmatten, ook voor het onderhoud van tuinen, parken en sportvelden zit u bij ons goed. Uiteraard kan u ook bij ons terecht voor de aanleg van uw droomtuin, het plaatsen van afsluitingen en het leggen van klinkers en alle tuinschrijnwerk.',
  // social
  socialLinks: [
    {
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://www.facebook.com/DeJeanTuinaanneming/',
    },
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/tuinendejean/',
    },
  ],
  emailId: 'tuinendejean@telenet.be',
  phone: '0032(0)495 22 43 48',
  address: 'Broechemsesteenweg 228 - 2560 Nijlen',
  vatNumber: 'BE 0723.520.822',
};
