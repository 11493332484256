import React from 'react';
export default function ContactForm() {
  return (
    <>
      <header>
        <h2>Contact</h2>
      </header>
      <div className="content">
        <p>
          Voor verdere vragen kan u onderstaand formulier invullen. U hoort spoedig van ons
        </p>
        <form action="https://formspree.io/xrgyvgbb" method="POST">
          <div className="fields">
            <div className="field half">
              <input type="text" name="name" id="name" placeholder="Naam" />
            </div>
            <div className="field half">
              <input type="email" name="_replyto" id="email" placeholder="Email" />
            </div>
            <div className="field half">
              <input type="tel" name="phone" id="phone" placeholder="Telefoon" />
            </div>
            <div className="field half">
              <input type="text" name="city" id="city" placeholder="Gemeente" />
            </div>
            <div className="field">
              <textarea
                name="message"
                id="message"
                placeholder="Korte beschrijving van de werken"
                rows="5"
              />
            </div>
          </div>
          <ul className="actions">
            <li>
              <input
                type="submit"
                value="Send Message"
                className="button primary"
              />
            </li>
          </ul>
        </form>
      </div>
    </>
  );
}
