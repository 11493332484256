import React from 'react';
import Scroll from './Scroll';
import config from '../../config';
import logo from '../assets/images/logo.png';

export default function Header() {
  return (
    <header>
      <img src={logo} alt="logo" class="logo"/>
      <h1>{config.heading}</h1>
      <p>{config.subHeading}</p>
      <ul className="actions">
      <li>
        <Scroll type="id" element="contact">
          <a href="#contact">
            Contact
          </a>
        </Scroll>
      </li>
        <li>
          <Scroll type="id" element="first">
            <a href="#first">
              Ons werk
            </a>
          </Scroll>
        </li>
      </ul>
    </header>
  );
}
